"use client"

import Slider, { Settings } from "react-slick"
import clsx from "clsx"
import { Children, Fragment, isValidElement, ReactNode, useEffect } from "react"

import { ClientTrackingEventName, IReview } from "../../../types"
import { useTracking } from "../../../hooks/tracking"
import { MostRecentReviewEntry } from "./ReviewEntry"

import classes from "./MostRecentReviews.module.scss"

interface IMostRecentReviewsProps {
  /**
   * list of reviews
   */
  reviews: IReview[]
}

interface IDotProps {
  className: string
  [key: string]: any
}

const renderDots = (dots: ReactNode[]) => {
  if (Children.count(dots) < 3) {
    return <ul>{dots}</ul>
  }
  const arr = Children.toArray(dots)
  const activeDotIndex = arr.findIndex(
    (dot) =>
      isValidElement<IDotProps>(dot) &&
      dot.props.className.includes("slick-active")
  )

  let prev
  let next

  if (activeDotIndex === 0) {
    prev = arr[arr.length - 1]
    next = arr[activeDotIndex + 1]
  } else if (activeDotIndex === arr.length - 1) {
    prev = arr[activeDotIndex - 1]
    next = arr[0]
  } else {
    prev = arr[activeDotIndex - 1]
    next = arr[activeDotIndex + 1]
  }

  return (
    <ul>
      <Fragment key={0}>{prev}</Fragment>
      <Fragment key={1}>{arr[activeDotIndex]}</Fragment>
      <Fragment key={2}>{next}</Fragment>
    </ul>
  )
}

const settigns: Settings = {
  arrows: false,
  autoplay: false,
  dots: true,
  dotsClass: clsx("slick-dots", classes.dots),
  easing: "ease-in",
  centerMode: false,
  pauseOnHover: true,
  slidesToShow: 1.3,
  className: classes.sliderContainer,
  slidesToScroll: 1,
  speed: 500,
  autoplaySpeed: 7000,
  variableWidth: true,
  appendDots: renderDots,
  responsive: [
    {
      breakpoint: 475,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ],
}

const MostRecentReviews = ({ reviews }: IMostRecentReviewsProps) => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent({
      eventName: ClientTrackingEventName.REVIEW_LIST_VIEWED,
      properties: {
        reviews: reviews.map((review, index) => ({
          rating: review.rating,
          text: review.text,
          name: review.advisor_name,
          position: index,
        })),
      },
    })
  }, [reviews, trackEvent])

  return (
    <div className={classes.root}>
      {/* @ts-ignore 'Slider' cannot be used as a JSX component. */}
      <Slider {...settigns} infinite={reviews.length > 1}>
        {reviews.map((review, index) => (
          <MostRecentReviewEntry key={review.id} {...review} position={index} />
        ))}
      </Slider>
    </div>
  )
}

export default MostRecentReviews
